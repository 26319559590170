import React from 'react'
import RowCard from '../Card/RowCard'

const Featured = () => {
    return (
        <div className='p-3'>
            <h2 className='text-[#fff] text-[16px] font-semibold'>Онцлох: </h2>
            <div className='grid grid-cols-2 max-md:flex max-md:flex-col'>
                <RowCard />
                <RowCard />
                <RowCard />
                <RowCard />
                <RowCard />
                <RowCard />
            </div>
        </div>
    )
}

export default Featured
