import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import BottomNavigationBar from './Component/BottomNavigation/BottomNavigation';
import Navbar from './Component/Navbar/Navbar';
import Categories from './Pages/Categoies/Categories';
import Favorite from './Pages/Favorite/Favorite';
import ProductDetial from './Pages/ProductDetial/ProductDetial';
import Login from './Pages/Login/Login';
import Profile from './Pages/Profile/Profile';

function App() {
  const userCache = JSON.parse(window.localStorage.getItem('user_shuriken'));

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/categories' element={<Categories />} />
          <Route path='/favorite' element={<Favorite />} />
          <Route path='/productdetial' element={<ProductDetial />} />
          <Route path='/login' element={<Login />} />
          <Route path='/profile' element={<Profile />} />
        </Routes>
        <BottomNavigationBar />
      </Router>
    </>
  );
}

export default App;