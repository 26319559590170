import React from 'react'
import MainCard from '../Card/MainCard'

const Products = () => {
    return (
        <div className='grid grid-cols-4 gap-10 max-md:grid max-md:grid-cols-2'>
            <MainCard />
            <MainCard />
            <MainCard />
            <MainCard />
            <MainCard />
        </div>
    )
}

export default Products
