import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CopyrightIcon from '@mui/icons-material/Copyright';

export default function Login() {
    return (
        <div className='flex flex-col items-center justify-center min-h-[80vh]'>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '50vw' },
                    '& button': { m: 1 }
                }}
                noValidate
                autoComplete="off"
                className='flex flex-col bg-slate-200 opacity-70 p-24 rounded-md max-md:p-10'
            >
                <h2 className='text-[30px] w-[25vw] font-semibold self-center text-center'>
                    Нэвтрэх
                </h2>
                <TextField id="filled-basic" sx={{ width: '20vw' }} type='email' label="Нэвтрэх нэр" variant="filled" />
                <TextField id="filled-basic" sx={{ width: '20vw' }} type='password' label="Нууц үг" variant="filled" />
                <Button sx={{ borderColor: '#333', color: '#333' }} variant="outlined" size="medium">
                    Нэвтрэх
                </Button>
                <div className='flex items-center justify-center gap-1'>
                    <div className='w-[9vw] border-[#333] border-[0.5px]' ></div>
                    <p>эсвэл</p>
                    <div className='w-[9vw] border-[#333] border-[0.5px]' ></div>
                </div>
                <Button sx={{ background: '#333', color: '#fff' }} variant="contained" size="medium">
                    Бүртгүүлэх
                </Button>
                <div className='flex text-[#333] mt-[10px] text-[12px] items-center justify-center gap-1'>
                    <CopyrightIcon sx={{ fontSize: '17px' }} />
                    <p>Matrix Solutions Lab 2024</p>
                </div>
            </Box>
        </div>
    );
}