import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList } from 'react-window';

function renderRow(props) {
    const { index, style, data } = props;

    return (
        <ListItem style={style} key={index} component="div" disablePadding>
            <ListItemButton>
                <ListItemText sx={{ color: '#A4E4FF' }} primary={`Бүлэг: ${data[index].name + 1}`} />
            </ListItemButton>
        </ListItem>
    );
}

const ProductDetial = () => {
    const [saved, setSaved] = useState(false);

    const data = [
        {
            name: 'name1',
        },
        {
            name: 'name2',
        },
        {
            name: 'name3',
        },
    ];

    return (
        <div className='flex flex-col items-center justify-center p-5 gap-10'>
            <img className='w-[15vw] h-[25vw] rounded-sm max-md:w-[40vw] max-md:h-[60vw]' src="https://imgsrv.crunchyroll.com/cdn-cgi/image/fit=contain,format=auto,quality=85,width=480,height=720/catalog/crunchyroll/a0ad4900ae610de44eb49dfc0881cc6a.jpe" alt="" />
            <div className='flex flex-col bg-black w-[60vw] p-5 rounded-t-2xl gap-10 max-md:w-[90vw]'>
                <p className={`text-[#A4E4FF]`}>Үргэлжилж байгаа</p>
                <div className='flex items-center justify-between'>
                    <div>
                        <h2 className='text-[#fff] text-[25px] font-semibold'>Viral Hit</h2>
                        <p className='text-[#838383] text-[14px] font-extralight'>Manhwa</p>
                    </div>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setSaved(!saved)}
                    >
                        {saved ?
                            <BookmarkIcon
                                sx={{
                                    fontSize: '30px',
                                    color: '#A4E4FF',
                                    '&.Mui-selected': {
                                        color: '#A4E4FF',
                                    }
                                }}
                            /> :
                            <BookmarkBorderIcon
                                sx={{
                                    fontSize: '30px',
                                    color: '#A4E4FF',
                                    '&.Mui-selected': {
                                        color: '#A4E4FF',
                                    }
                                }}
                            />
                        }
                    </IconButton>
                </div>
                <div className='bg-[#979797] flex flex-wrap justify-between px-10 py-3 rounded-md text-[#fff] font-semibold'>
                    <div className='flex flex-col items-center justify-center'>
                        <p >Үнэлгээ</p>
                        <p>4.2</p>
                    </div>
                    <div className='border-r-[1px] border-[#fff]'></div>
                    <div className='flex flex-col items-center justify-center'>
                        <p>Нийт бүлэг</p>
                        <p>127</p>
                    </div>
                    <div className='border-r-[1px] border-[#fff]'></div>
                    <div className='flex flex-col items-center justify-center'>
                        <p>Хэл</p>
                        <p>Монгол</p>
                    </div>
                </div>
                <p className='text-[#f0f0f0] text-[15px] font-extralight' >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis, in tenetur? Rem velit expedita, accusantium cupiditate labore in pariatur tempore?
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique, perspiciatis soluta laboriosam harum qui maiores enim omnis quod perferendis a vel ea labore dolorum nisi quibusdam quae fugit minus unde iure explicabo. Ratione excepturi laborum aliquam, labore eligendi fugit nobis quidem animi facere cum deleniti totam sunt minus et rem quibusdam sapiente asperiores, nostrum maxime numquam porro non eum illo doloribus. Dolorum neque vel quam labore repellendus id, placeat consequuntur, excepturi ut eos fugit consectetur explicabo voluptates assumenda facere voluptatibus cumque a illo sint, eaque maiores dolor nulla dolores fuga? Assumenda impedit illum esse autem odit, nam iure nesciunt culpa animi veniam cum reprehenderit porro eaque nobis facere dolor numquam consequatur adipisci corrupti doloribus? Amet deserunt quos doloremque magni a!
                </p>
                <div className='flex flex-col gap-3'>
                    <h2 className='text-[20px] text-[#fff] font-semibold'>Бүлгүүд</h2>
                    <Box
                        sx={{ width: '100%', height: 400, maxWidth: '100%', bgcolor: '#333', borderRadius: '15px' }}
                    >
                        <FixedSizeList
                            height={400}
                            width={'100%'}
                            itemSize={46}
                            itemCount={data.length}
                            overscanCount={5}
                            itemData={data}
                        >
                            {renderRow}
                        </FixedSizeList>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default ProductDetial
