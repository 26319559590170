import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { Link } from 'react-router-dom';

const Categories = () => {
    return (
        <div className='p-3'>
            <h2 className='text-[#fff]'>Төрөл Сонгох:</h2>
            <Swiper
                className='mt-3'
                spaceBetween={50}
                slidesPerView={window.innerWidth > 720 ? 7 : 3}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide>
                    <div className='bg-[#A9B0B9] border-[#C0C0C0] border text-center rounded-xl text-[#333333] max-md:w-[max-content] max-md:px-2'>
                        <Link>
                            Адал явдалт
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='bg-[#A9B0B9] border-[#C0C0C0] border text-center rounded-xl text-[#333333] max-md:w-[max-content] max-md:px-2'>
                        <Link>
                            Адал явдалт
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='bg-[#A9B0B9] border-[#C0C0C0] border text-center rounded-xl text-[#333333] max-md:w-[max-content] max-md:px-2'>
                        <Link>
                            Адал явдалт
                        </Link>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='bg-[#A9B0B9] border-[#C0C0C0] border text-center rounded-xl text-[#333333] max-md:w-[max-content] max-md:px-2'>
                        <Link>
                            Адал явдалт
                        </Link>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default Categories
