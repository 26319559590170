import React from 'react'
import Slider from '../../Component/Home/Slider'
import Categories from '../../Component/Home/Categories'
import Featured from '../../Component/Home/Featured'

const Home = () => {
    return (
        <div className='overflow-y-scroll min-h-[100vh] mb-20'>
            <Slider />
            <Categories />
            <Featured />
        </div>
    )
}

export default Home
