import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { BsCalendar2Date } from "react-icons/bs";
import BorderBox from './BorderBox';
import { RiCoinsLine } from "react-icons/ri";
import List from '@mui/material/List';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListItemButton from '@mui/material/ListItemButton';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ListItemIcon from '@mui/material/ListItemIcon';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import CopyrightIcon from '@mui/icons-material/Copyright';

export default function Profile() {
    const [selectedIndex, setSelectedIndex] = useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };
    return (
        <div className='flex flex-col items-center gap-10 p-3'>
            <div className='flex flex-col items-center justify-center gap-3'>
                <Stack direction="row" spacing={2}>
                    <Avatar sx={{ width: '80px', height: '80px', fontSize: '35px', bgcolor: '#A4E4FF' }}>H</Avatar>
                </Stack>
                <h2 className=' text-[18px] font-semibold text-[#fff]'>Золбоотбаатар</h2>
            </div>
            <div className='grid grid-cols-2 gap-3'>
                <BorderBox text={'16 өдөр үлдсэн'} icon={<BsCalendar2Date color='#A4E4FF' size={25} />} />
                <BorderBox text={'Зоос'} icon={<RiCoinsLine color='#A4E4FF' size={25} />} />
            </div>

            <List component="nav" aria-label="main mailbox folders">
                <ListItemButton
                    selected={selectedIndex === 0}
                    onClick={(event) => handleListItemClick(event, 0)}
                >
                    <ListItemIcon>
                        <ManageAccountsIcon sx={{ color: '#fff' }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: '#fff' }} primary="Мэдээлэл өөрчлөх" />
                </ListItemButton>
                <ListItemButton
                    selected={selectedIndex === 1}
                    onClick={(event) => handleListItemClick(event, 1)}
                >
                    <ListItemIcon>
                        <TurnedInIcon sx={{ color: '#fff' }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: '#fff' }} primary="Миний хадгалсан" />
                </ListItemButton>
                <ListItemButton
                    selected={selectedIndex === 2}
                    onClick={(event) => handleListItemClick(event, 2)}
                >
                    <ListItemIcon>
                        <PermContactCalendarIcon sx={{ color: '#fff' }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: '#fff' }} primary="Холбоо барих" />
                </ListItemButton>
                <div className='w-[15vw] my-3 border-[#fff] border-[0.5px]' ></div>
                <ListItemButton
                    selected={selectedIndex === 3}
                    onClick={(event) => handleListItemClick(event, 3)}
                >
                    <ListItemIcon>
                        <LogoutIcon sx={{ color: 'red' }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: 'red' }} primary="Гарах" />
                </ListItemButton>
                <div className='flex text-[#d3d3d3] mt-[10px] text-[12px] items-center justify-center gap-1'>
                    <CopyrightIcon sx={{ fontSize: '17px' }} />
                    <p>Matrix Solutions Lab 2024</p>
                </div>
            </List>
        </div>
    );
}
