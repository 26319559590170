import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import MainCard from '../Card/MainCard';

const Slider = () => {
    return (
        <div className='p-3 flex flex-col gap-3 justify-start items-start w-[100vw]'>
            <h2 className='text-[#fff]'>Сүүлд нэмэгдсэн:</h2>
            <Swiper
                spaceBetween={50}
                slidesPerView={7}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                className='self-start w-screen max-md:w-[95vw]'
            >
                <SwiperSlide>
                    <MainCard />
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default Slider
