import React from 'react'

const MainCard = () => {
    return (
        <div className='cursor-pointer custom-hover p-1 w-[max-content]'>
            <img className='w-[12vw] h-[16vw] rounded-sm max-md:w-[30vw] max-md:h-[45vw]'
                src="https://m.media-amazon.com/images/M/MV5BMzUxOTBlNzMtYzAyZi00MDNhLWE3OGUtYWQ5MzQyZGE1OGYwXkEyXkFqcGdeQXVyMTEzMTI1Mjk3._V1_.jpg" alt="" />
            <h2 className='text-[18px] font-semibold text-[#fff]'>Kaiju #8</h2>
            <p className='text-[14px] text-[#F78500]'>Бүлэг 121</p>
        </div>
    )
}

export default MainCard
