import React from 'react'
import RowCard from '../../Component/Card/RowCard'

const Favorite = () => {
    return (
        <div className='flex flex-wrap items-center justify-center min-h-screen'>
            <RowCard />
            <RowCard />
            <RowCard />
            <RowCard />
        </div>
    )
}

export default Favorite
