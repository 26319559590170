import React from 'react'
import SwitchMangaManhwa from '../../Component/Categories/SwitchMangaManhwa'
import Products from '../../Component/Categories/Products'
import SuggestionSwiper from '../../Component/Categories/SuggestionSwiper'

const Categories = () => {
    return (
        <div className='flex flex-col items-center p-10 gap-5 max-md:p-3'>
            <SuggestionSwiper />
            <SwitchMangaManhwa />
            <Products />
        </div>
    )
}

export default Categories
