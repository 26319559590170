import React from 'react'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { BorderAll } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconEmpty': {
        color: '#FFD700',
    },
    '& .MuiRating-iconFilled': {
        color: '#FFD700'
    },
});

const RowCard = () => {
    return (
        <div className='p-3 pl-5 flex items-center w-[max-content] max-md:w-[95vw]'>
            <img className='w-[12vw] h-[17vw] rounded-sm max-md:w-[30vw] max-md:h-[45vw]'
                src="https://fbi.dek-d.com/27/0935/2712/133461940" alt="" />
            <div className='p-5 h-[13vw] bg-black flex flex-col max-md:w-[60vw] items-start text-[#fff] gap-5 
            rounded-md max-md:h-[max-content]'>
                <h2 className='font-semibold'>Буцаж ирсэн БААТАР</h2>
                <h2 className='text-[13px] font-extralight max-md:flex max-md:flex-wrap max-md:w-[55vw]'>Буцаж ирсэн баатар өшөөгөө авах болно.</h2>
                <div className='flex items-center gap-3'>
                    <Stack spacing={2} >
                        <StyledRating name="half-rating" defaultValue={2.5} precision={0.5} readOnly />
                    </Stack>
                    <p className='text-[15px] font-extralight'>3.5</p>

                </div>
                <div className='bg-[#A9B0B9] custom-hover px-4 py-[1px] border-[#C0C0C0] border text-center rounded-xl text-[#333333]'>
                    <Link>
                        Адал явдалт
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default RowCard
